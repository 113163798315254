import { getCode } from 'src/request/api'
import { userAsync } from 'src/store/async'
import { RequestLoginParams } from 'src/types'
import { userStore, chatStore } from 'src/store'

import { useNavigate, useLocation, Link } from 'react-router-dom';

import {
  HeartFilled,
  LockOutlined,
  MailOutlined,
  RedditCircleFilled,
  RobotOutlined,
  SlackCircleFilled,
  SmileFilled,
  TwitterCircleFilled,
    WechatOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { LoginForm, ProFormCaptcha, ProFormText } from '@ant-design/pro-form'
import { Form, FormInstance, Modal, Space, Tabs, Button, Row, Col,Typography, Card, message, Spin, Checkbox } from 'antd'
import { useState, useEffect } from 'react'
import axios from 'axios';

import './login.css'

type Props = {
  open: boolean
  onCancel: () => void
}

const { Title, Paragraph } = Typography;

type LoginType = 'code' | 'password' | string;

export function LoginCard(props: {
  form: FormInstance<RequestLoginParams>
  onSuccess: () => void
}) {

  const [loginType, setLoginType] = useState<LoginType>('code');
  const [isLoading, setIsLoading] = useState(true);
    const [qrCode, setQrCode] = useState('');
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isAgreed, setIsAgreed] = useState(false);
    const { initialChatInfo } = chatStore()

    const handleMobileLogin = () => {
        const encodedUrl = encodeURIComponent('https://www.matebotai.com')
        const weChatOAuth2Link = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb513d3e3e1c9ea5&redirect_uri=${encodedUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        window.location.href = weChatOAuth2Link;
    };

    const handleAgreementChange = (e:any) => {
        setIsAgreed(e.target.checked);
      };

    async function reloadQrCode() {
        setIsLoading(true);
        setReload(false);
        axios({
              method: 'get',
              url: '/api/qrcode',
              data: {
                  'type': 'qrcode',
              },
            }).then(function (response) {
                const data = response.data;
                if (data.type === 'qrcode') {
                    setQrCode('data:image/png;base64,' + data.qrcode);
                    setIsLoading(false);
                    const sceneStr = data.scene_str;
                    const fetchData = async () => {
                      try {
                        const response = await axios.post('/api/wx_login', {
                            'sceneId': sceneStr
                        });
                        const data = await response.data;
                        if (!data.code) {
                            userStore.getState().login({ ...data.data })
                            if (data.dialog.length !== 0) {
                                    initialChatInfo(data.dialog);
                                }
                            if (location.pathname === 'login'){
                                navigate('/');
                            } else {
                                props.onSuccess();
                            }
                            clearInterval(intervalId); // 清除定时器
                        }
                      } catch (error) {
                        message.error('非常抱歉呐，服务器维护中，请稍后再试！');
                        setReload(true);
                        clearInterval(intervalId); // 清除定时器
                      }
                    };

                    const intervalId = setInterval(fetchData, 2000);
                    setTimeout(() => {
                      // 重新请求二维码数据的逻辑
                        clearInterval(intervalId);
                      setReload(true);
                    }, 120 * 1000);
                }
            }).catch(function (error) {
                message.error('非常抱歉，二维码获取失败，请稍后再试！');
            })
    }

    useEffect(() => {
        if (window.innerWidth > 576) {
            axios({
                method: 'get',
                url: '/api/qrcode',
                data: {
                    'type': 'qrcode',
                },
            }).then(function (response) {
                const data = response.data;
                if (data.type === 'qrcode') {
                    setQrCode('data:image/png;base64,' + data.qrcode);
                    setIsLoading(false);
                    const sceneStr = data.scene_str;
                    const fetchData = async () => {
                        try {
                            const response = await axios.post('/api/wx_login', {
                                'sceneId': sceneStr
                            });
                            const data = await response.data;
                            if (!data.code) {
                                userStore.getState().login({...data.data})
                                if (data.dialog.length !== 0) {
                                    initialChatInfo(data.dialog);
                                }
                                if (location.pathname === 'login') {
                                    navigate('/');
                                } else {
                                    props.onSuccess();
                                }
                                clearInterval(intervalId); // 清除定时器
                            }
                        } catch (error) {
                            message.error('非常抱歉呐，服务器维护中，请稍后再试！');
                            setReload(true);
                            clearInterval(intervalId); // 清除定时器
                        }
                    };

                    const intervalId = setInterval(fetchData, 2000);
                    setTimeout(() => {
                        // 重新请求二维码数据的逻辑
                        clearInterval(intervalId);
                        setReload(true);
                    }, 120 * 1000);
                }
            }).catch(function (error) {
                message.error('非常抱歉，二维码获取失败，请稍后再试！');
            })
        }

    }, []);

  return (
    <div>
      <Tabs
        centered
        activeKey={loginType}
        onChange={(activeKey) => {
          setLoginType(activeKey)
        }}
      >
        <Tabs.TabPane key="code" tab="扫码登录" />
        {/*<Tabs.TabPane key="password" tab="密码登录" />*/}
      </Tabs>
      {
        loginType === 'code' && (
          <Row justify="center">
              { isAgreed &&
                  (window.innerWidth > 576 ? (
                      <div style={{display: 'grid', justifyContent: 'center', width: '100%'}}>
                          <Paragraph style={{ fontSize: 26}}>
                              <WechatOutlined style={{marginRight: '10px', marginBottom: 0, color: '#95ec69'}} />
                              微信扫码登录
                          </Paragraph>
                            <div style={{ width: 200, height: 200,  borderRadius: 15, border: 'solid 1px gray', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                                { isLoading ? (
                                      <Spin size="large" />
                                  ) : reload ? (
                                      <div style={{backgroundImage: `url(${qrCode})`}} className="reload-container">
                                          <div className="overlay">
                                              <Title level={5}>登录超时</Title>
                                              <Button type="link" icon={<ReloadOutlined />} onClick={reloadQrCode}>重新加载</Button>
                                          </div>
                                      </div>
                                  ) : (
                                      <img style={{objectFit: 'cover', width: '100%', height: '100%' }} src={qrCode} />
                                )}
                            </div>
                            <Paragraph style={{color: 'black', fontSize: 18, marginTop: 5}}>使用微信扫码关注公众号登录</Paragraph>
                      </div>
                  ) : (
                      <div style={{display: 'grid', justifyContent: 'center', width: '100%'}}>
                          <Button type="primary" style={{backgroundColor: '#95ec69', color: 'black'}} onClick={handleMobileLogin}>
                              <WechatOutlined style={{marginRight: '5px', color: '#2e8600'}} />微信一键登录
                          </Button>
                      </div>
                )
              )}
              <Col span={24} style={{marginTop: 10, justifyContent: 'center', display: 'flex'}}>
                  <Checkbox checked={isAgreed} onChange={handleAgreementChange}>
                    我已阅读并同意<Link to="/terms">《服务条款》</Link>和<Link to="/privacy">《隐私政策》</Link>
                  </Checkbox>
              </Col>
          </Row>
        )
      }
      {
        loginType === 'password' && (
        <div>
           {/* <ProFormText*/}
           {/* fieldProps={{*/}
           {/*   size: 'large',*/}
           {/*   prefix: <RobotOutlined />*/}
           {/* }}*/}
           {/* name="account"*/}
           {/* rules={[*/}
           {/*   {*/}
           {/*     required: true,*/}
           {/*   }*/}
           {/* ]}*/}
           {/*/>*/}
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder="请输入密码"
            rules={[
              {
                required: true,
                message: '8位及以上至少包含一个字母和一个数字',
                pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
              },
            ]}
          />
        </div>
        )
      }
      <div
        style={{
          marginBlockEnd: 24
        }}
      />
    </div>
  )
}

// 登录注册弹窗
function LoginModal(props: Props) {
  const [loginForm] = Form.useForm()

  const onCancel = () => {
    props.onCancel()
    loginForm.resetFields()
  }

  return (
    <Modal open={props.open} footer={null} destroyOnClose onCancel={onCancel}>
      <LoginCard form={loginForm} onSuccess={onCancel} />
    </Modal>
  )
}

export default LoginModal
