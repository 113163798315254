import { ChatsInfo } from 'src/types';
import { generateUUID } from './generateUUID';

export function generateChatInfo (name: string, uuid: string, systemPrompt: string): ChatsInfo  {
    if (uuid === '') {
        const new_uuid = generateUUID();

        return {
            path: new_uuid,
            id: new_uuid,
            name: name,
            roleChar: 'general',
            data: [],
            config: {
                name: name,
                system_prompt: systemPrompt,
                upload_file: []
            }
        }
    } else {
        return {
            path: uuid,
            id: uuid,
            name: name,
            data: [],
            roleChar: 'general',
            config: {
                name: name,
                system_prompt: systemPrompt,
                upload_file: []
            }
        }
    }
}
