import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { ChatGpt, ChatsInfo, RoleConfig } from 'src/types'
import { formatTime, generateChatInfo } from 'src/utils'

export interface ChatState {
    // 聊天对话
    chats: Array<ChatsInfo>
    // 当前选择的会话id
    selectChatId: string | number
    // 新增一个对话
    addChat: (name: string, uuid: string) => void
    // 删除一个对话
    delChat: (id: string | number) => void
    // 清空所有对话
    clearChats: () => void
    // 修改数字人属性
    changeRoleConfig: (id: string | number, name: string, system_prompt: string, roleChar: string, upload_file?: any) => void
    // 改变选择会话ID
    changeSelectChatId: (id: string | number) => void
    // 初始化历史对话
    initialChatInfo: (data: Array<ChatsInfo>) => void
    // 给对话添加数据
    setChatInfo: (
    id: string | number,
    data?: ChatGpt,
    info?: ChatsInfo | { [key: string]: any }
    ) => void
    // 修改对话数据
    setChatDataInfo: (
    id: string | number,
    messageId: string | number,
    info?: ChatGpt | { [key: string]: any }
    ) => void
    // 设置对话历史记录
    setChatRecord: (
        id: string | number,
        data?: ChatGpt
    ) => void
    // 清理当前会话
    clearChatMessage: (id: string | number) => void
    // 删除某条消息
    delChatMessage: (id: string | number, messageId: string | number) => void
}

const chatStore = create<ChatState>()(
  persist(
    (set, get) => ({
      chats: [],
      selectChatId: '',
        initialChatInfo: (data) =>
            set((state: ChatState) => {
                if (data.length > 0) {
                    const filteredNewList = data.filter((newItem: {
                        id: string, path: string, name: string, data: Array<ChatGpt>,
                        roleChar: string, config: RoleConfig
                    }) => {
                        // 使用 some 方法检查是否有原始列表中具有相同 ID 的字典
                        const hasDuplicate = state.chats.some((originalItem) => originalItem.id === newItem.id);

                        // 如果没有相同的 ID，返回 true 以保留该字典
                        return !hasDuplicate;
                    });
                    const newChats = filteredNewList
                    return {
                        chats: [...newChats],
                        selectChatId: newChats[0].id
                    }
                } else {
                    return state
                }
            }),
          addChat: (name: string, uuid: string) =>
            set((state: ChatState) => {
              const info = generateChatInfo(name, uuid, '')
              const newChats = [...state.chats]
              newChats.unshift({ ...info })
              return {
                chats: [...newChats],
                selectChatId: info.id
              }
            }),
          delChat: (id) =>
            set((state: ChatState) => {
              const newChats = state.chats.filter((c) => c.id !== id)
              if (state.chats.length <= 1) {
                const info = generateChatInfo('MateBot', '', '')
                return {
                  chats: [{ ...info }],
                  selectChatId: info.id
                }
              }
              return {
                selectChatId: state.chats[1].id,
                chats: [...newChats]
              }
            }),
          clearChats: () =>
            set(() => {
              const info = generateChatInfo('MateBot', '', '')
              return {
                chats: [{ ...info }],
                selectChatId: info.id
              }
            }),
          changeSelectChatId: (id) =>
            set(() => ({
              selectChatId: id
            })),
          changeRoleConfig: (id: string | number, name: string, system_prompt: string, roleChar: string, upload_file?: any) =>
              set((state: ChatState) => {
              const newChats = state.chats.map((item) => {
                if (item.id === id) {
                    const config = {name, system_prompt, upload_file}
                  return {
                    ...item,
                    name,
                    roleChar,
                    config,
                  }
                }
                return item
              })
              return {
                chats: newChats
              }
            }),
          delChatMessage: (id, messageId) =>
            set((state: ChatState) => {
              const newChats = state.chats.map((c) => {
                if (c.id === id) {
                  const newData = c.data.filter((d) => d.id !== messageId)
                  return {
                    ...c,
                    data: newData
                  }
                }
                return c
              })
              return {
                chats: newChats
              }
            }),
          clearChatMessage: (id) =>
            set((state: ChatState) => {
              const newChats = state.chats.map((c) => {
                if (c.id === id) {
                  return {
                    ...c,
                    data: []
                  }
                }
                return c
              })
              return {
                chats: newChats
              }
            }),
          setChatInfo: (id, data, info) =>
            set((state: ChatState) => {
              let updatedItem = null;
              const filteredChats = state.chats.filter((item) => {
                if (item.id === id) {
                  // 更新项目并保存在临时变量中
                  updatedItem = {
                    ...item,
                    ...info,
                    data: data ? item.data.concat({ ...data }) : item.data
                  };
                  // 返回false，因为我们将单独处理这个项目
                  return false;
                }
                // 保持其他项不变
                return true;
              });
              if (updatedItem) {
                return {
                    chats: [updatedItem, ...filteredChats]
                };
              } else {
                return {
                    chats: filteredChats
                }
              }
            }),
        setChatRecord: (id, data) =>
            set((state: ChatState) => {
              const newChats = state.chats.map((item) => {
                if (item.id === id && Array.isArray(data)) {
                  return {
                    ...item,
                    data: data
                  }
                }
                return item
              })
              return {
                chats: newChats
              }
            }),
          setChatDataInfo: (id, messageId, info) =>
            set((state: ChatState) => {
              const newChats = state.chats.map((item) => {
                if (item.id === id) {
                  const newData = item.data.map((m) => {
                    if (m.id === messageId) {
                      return {
                        ...m,
                        ...info
                      }
                    }
                    return m
                  })

                  const dataFilter = newData.filter((d) => d.id === messageId)
                  const chatData = { id: messageId, ...info } as ChatGpt
                  return {
                    ...item,
                    data: dataFilter.length <= 0 ? [...newData, { ...chatData }] : [...newData]
                  }
                }
                return item
              })

              return {
                chats: newChats
              }
            })
        }),
    {
      name: 'chat_storage', // name of item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage) // (optional) by default the 'localStorage' is used
    }
  )
)

export default chatStore
