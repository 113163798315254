import { message } from 'antd'

import { getConfig } from 'src/request/api'
import configStore from './slice'
import chatStore  from 'src/store/chat/slice'


async function fetchConfig() {
  const res = await getConfig()
  if (!res.code) {
	configStore.getState().replaceData(res.data)
    const dialogRecords = res.data.dialog
    if (dialogRecords !== undefined) {
      chatStore.getState().clearChats();
      chatStore.getState().initialChatInfo(dialogRecords);
      message.success(res.message)
    }
  }
  return res
}

export default {
  fetchConfig
}
