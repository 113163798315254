import styles from './index.module.less'

function KnowbotLogo(props: { rotate?: boolean; width?: string; height?: string }) {
  const { rotate, width = '1.2em', height = '1.2em' } = props

  return (
    <svg
        className={styles.logo}
      viewBox="0 0 32 32"
      aria-hidden="true"
      width={width}
      height={height}
    >
      <image  href="/logo.png" height="100%" width="100%" />
    </svg>
  )
}

export default KnowbotLogo
